<template>
  <section class="Add-Content">
    

    <div class="page-header mb-3">
      <h3 class="page-title">Add Content</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb p-0">
          <li class="breadcrumb-item">
            <router-link to="/content-page/content-page" class="text-primary">Content
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Add Content
          </li>
        </ol>
      </nav>

    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">

          <div class="card-body">
            <form @submit.prevent="addContentPage">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">Name</label>
                    <input type="text" v-model="addContent.name" id="name" name="name" class="form-control" :class="{
                      'is-invalid': submitted && $v.addContent.name.$error,
                    }" />
                    <div v-if="submitted && !$v.addContent.name.required" class="invalid-feedback">
                      Name is required
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="title">Title</label>
                    <input type="text" v-model="addContent.title" id="title" name="title" class="form-control" :class="{
                      'is-invalid': submitted && $v.addContent.title.$error,
                    }" />
                    <div v-if="submitted && !$v.addContent.title.required" class="invalid-feedback">
                      Title is required
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="content">Content</label>
                    <vue-editor v-model="addContent.content" id="content" name="content" use-custom-image-handler
                      @image-added="handleImageAdded" :class="{
                        'is-invalid': submitted && $v.addContent.content.$error,
                      }"></vue-editor>
                    <div v-if="submitted && !$v.addContent.content.required" class="invalid-feedback">
                      Content is required
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group float-right">
                    <button class="btn btn btn-add btn-primary">Submit</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import axios from "axios";
import { contentApi } from "../../api";
import { required } from "vuelidate/lib/validators";
import { VueEditor } from "vue2-editor";

const baseURL = process.env.VUE_APP_BASE_URL;
export default {
  name: "add-new-content",
  components: {
    VueEditor,
    
  },

  data() {
    return {
      addContent: {
        name: "",
        title: "",
        content: "",
      },
      submitted: false,
      
    };
  },
  validations: {
    addContent: {
      name: { required },
      title: { required },
      content: { required },
    },
  },
  methods: {
    async addContentPage() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      
      const data = await contentApi.addContent({
        ...this.addContent,
      });
      
      this.$router.push("/content-page/content-page");
      if (data.status == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.messages,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.messages,
        });
      }
    },
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData();
      let pages = "pages";
      formData.append("file", file);
      formData.append("folder", pages);
      axios({
        url: `${baseURL}/api/media/file/upload`,
        method: "POST",
        data: formData,
      })
        .then((result) => {
          let url = result.data.data.path; // Get url from response
          url = `${baseURL}/public/${url}`;
          Editor.insertEmbed(cursorLocation, "image", url);

          resetUploader();
        })
        .catch((err) => {
          alert("image not uploaded " + err);
        });
    },
  },
};
</script>